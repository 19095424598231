
@font-face {
  font-family: "Bitstream-Vera-Sans-Mono-Roman"; 
  font-style: normal;
  font-weight: 400;
  src: url('fonts/Bitstream-Vera-Sans-Mono-Roman.woff2') format('woff2'),
       url('fonts/Bitstream-Vera-Sans-Mono-Roman.woff') format('woff')
}


html { }
body { margin:0;padding:0; color: #000; font-weight: normal; font-family:'Bitstream-Vera-Sans-Mono-Roman', sans-seri   }
html, h1, h2, h3, h4, h5, h6, form, fieldset, img {margin:0;padding:0;border:0}
h1, h2, h3, h4, h5, h6 {font-size:1em;font-family:'Bitstream-Vera-Sans-Mono-Roman', sans-serif}
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {display:block}

ul, dl,dt,dd {margin:0;padding:0;list-style:none}
legend {position:absolute;margin:0;padding:0;font-size:0;line-height:0;text-indent:-9999em;overflow:hidden}
label, input, button, select, img {vertical-align:middle;font-size:1em}
input, button {margin:0;padding:0;font-family:'Bitstream-Vera-Sans-Mono-Roman', sans-serif;font-size:1em}
input[type="submit"] {cursor:pointer}
button {cursor:pointer}

textarea, select {font-family:'Bitstream-Vera-Sans-Mono-Roman', sans-serif; font-size:1em}
select {margin:0}
p {margin:0;padding:0;word-break:break-all}
hr {display:none}
pre {overflow-x:scroll;font-size:1.1em}
a {color:#000;text-decoration:none}

*, :after, :before {
  -webkit-box-sizing:border-box;
  -moz-box-sizing:border-box;
  box-sizing:border-box;
}

input[type=text],input[type=password], textarea {
-webkit-transition:all 0.30s ease-in-out;
-moz-transition:all 0.30s ease-in-out;
-ms-transition:all 0.30s ease-in-out;
-o-transition:all 0.30s ease-in-out;
outline:none;
}

input[type=text]:focus,input[type=password]:focus, textarea:focus,select:focus {
-webkit-box-shadow:0 0 5px #9ed4ff;
-moz-box-shadow:0 0 5px #9ed4ff;
box-shadow:0 0 5px #9ed4ff;
border:1px solid #558ab7 !important;
}

button { margin: 0; padding: 0; background: none; border: none; }
img { max-width: 100%; }


#wrap { width: 100%; min-height: 100vh; background: #fff url('images/bg/bg-offo-pc.png') no-repeat center center; background-size: cover; }


header { position: relative; padding: 1.35416666667vw 0.9375vw; display: flex; justify-content: space-between; align-items: center; }
header .head { width: 100%; display: flex; justify-content: space-between; align-items: center; }
header .logo { order: 2; width: 25.78125vw; }
header .wallet button { display: inline-block; padding: 0 0.78125vw; line-height: 1.71875vw; font-size: .9375vw;  border-radius: 1.5625vw;  text-transform: uppercase; background: #fff; border: 1px solid #000; }
header .creator-txt { order: 3; width: 35.41666666667vw; }
header .creator-txt2 { order: 1; width: 35.41666666667vw; }

.conntectWallet { margin-top: 0.83333333333vw; text-align: center; }

.minTingAction { position: absolute; left: 0; bottom: 4.79166666667vw; width: 100%; text-align: center; }
.minTingAction strong { display: inline-block; vertical-align: bottom; width: 8.22916666667vw; line-height: 1.14583333333vw; font-size: 1.04166666667vw; font-weight: normal; background: #fff; }
.minTingAction .minTingApp { display: flex; justify-content: space-between; padding: 0 0.78125vw; width: 78.125vw; height: 3.22916666667vw; margin: 0 auto; background: #0A26FB; }
.minTingAction .minTingApp dl { display: flex; align-items: center; }
.minTingAction .minTingApp dl dt { position: relative; padding-right: 2.1875vw; margin-right: 2.1875vw; font-size: 1.45833333333vw; color: #fff; text-transform: uppercase; }
.minTingAction .minTingApp dl dt::after { content: ':'; position: absolute; right: 0px; top: 50%; transform: translateY(-55%);}
.minTingAction .minTingApp dl dd {  font-size: 1.45833333333vw; color: #fff; display: flex; }
.minTingAction .minTingApp dl dd .btnAmount { width: 1.30208333333vw; height: 1.30208333333vw; font-size: 1.25vw; color: #fff; }
.minTingAction .minTingApp dl dd .minus { order: 1; }
.minTingAction .minTingApp dl dd .plus { order: 3; }
.minTingAction .minTingApp dl dd input { order: 2; width: 5.20833333333vw; text-align: center; color: #fff; background: none; border: none; }
.minTingAction input[type='text'] { border: 0 !important; outline: 0 !important; background: none; transition: 0; }
.minTingAction input[type='text']:focus { border: 0 !important; outline: 0 !important;  border-color: inherit; -webkit-box-shadow: none; box-shadow: none; }

.minterView {  }

.minTingAction { text-align: center; }
.btnMinting { margin-top: 2.8125vw; width: 36.14583333333vw; height: 3.95833333333vw; padding-top: 0.8vw; font-size: 1.5625vw; color: #fff; text-align: center; background: url(images/bg/btnMintin.png) no-repeat center center; background-size: contain; filter: drop-shadow(0px 0px 1.04166666667vw #fff); }
.btnMinting:disabled { filter: none; background: url(images/bg/btnMintin-disabled.png) no-repeat center center; background-size: contain; color: #D0D0D0; }


.btnType01 { display: inline-block; padding: 0 0.78125vw; line-height: 1.71875vw; font-size: .9375vw; border-radius: 1.5625vw; text-transform: uppercase; background: #fff; border: 1px solid #000; }
.btnType01:hover { color: #fff; background: #000; border: 1px solid #fff; }
.btnType01:disabled { opacity: 0.3; color: #000; background: #fff; border: 1px solid #000;  }
.btnType01:disabled:hover { opacity: 0.3; color: #000; background: #fff; border: 1px solid #000; }

button.connect { padding-left: 2.5vw; background: #fff url('images/img/metamask-fox.svg') no-repeat left 0.8vw center; background-size:  auto 60%;}
button.connect:hover { color: #fff; background: #000 url('images/img/metamask-fox.svg') no-repeat left 0.8vw center; background-size:  auto 60%; border: 1px solid #fff; }

.disn { display: none; }

.modal .modal-content { position: fixed; top: 50%; left: 50%; line-height: 1.25vw; z-index: 99; width: 23.17708333333vw; height: auto; padding: 1.04166666667vw; transform: translate(-50%,-50%); animation: modal-bg-show 0.3s; background: #fff; box-shadow: 0px 0px 39px rgba(0, 0, 0, 0.25); text-transform: uppercase; }
.modal .modal-background { position: fixed; left: 0px; top: 0; width: 100%; height: 100%; opacity: 1; backdrop-filter: blur(0.703125vw); }
.modal .modal-content__close { position: absolute; right: 1.04166666667vw; top: 1.04166666667vw;  }
.modal .modal-content__close svg { width: 1.25vw; height: 1.25vw; }

.remain { width: 78.125vw; margin: 0.52083333333vw auto 0; text-align: left; font-size: 0.83333333333vw; color: #00ED18; }

@keyframes modal-show {
  from {
    opacity: 0;
    margin-top: -50px;
  }
  to {
    opacity: 1;
    margin-top: 0;
  }
}
@keyframes modal-bg-show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media screen and (max-width: 768px) {
  #wrap { background: #fff url('images/bg/bg-offo-mobi.png') no-repeat center center; background-size: cover; }

  header { position: relative; padding: 0; display: flex; justify-content: space-between; align-items: center; }
  header .head { width: 100%; display: flex; flex-wrap: wrap; justify-content: space-between; align-items: center; }
  header .logo { margin-top: 5.55555555556vw; order: 3; width: 100%; padding: 0 20.83333333333vw; }
  header .creator-txt { order: 2; width: 50%;  }
  header .creator-txt2 { order: 1; width: 50%;  }

  .minTingBox { order: 2; padding: 2.77777777778vw; background: #0A26FB; }
  .minTingAction { display: flex; flex-wrap: wrap; text-align: left; bottom: 8.33333333333vw; }
  .minTingAction > div { width: 100%; }
  .minTingAction strong { width: 31.94444444444vw; line-height: 5vw; font-size: 4.44444444444vw; text-align: center; }
  .minTingAction .minTingApp { display: block; width: 100%; height: auto; padding: 0; }

  .minTingAction .minTingApp dl { margin-top: 2.77777777778vw; }
  .minTingAction .minTingApp dl dt { position: relative; width: 40%; padding-right: 1.1875vw; margin-right: 3.33333333333vw; font-size: 5vw; }
  .minTingAction .minTingApp dl dt::after { content: ':'; margin-left: 5.11111111111vw; position: relative; }
  .minTingAction .minTingApp dl dd { display: inline-block; width: calc( 60% - 3.33333333333vw); text-align: center; font-size: 5vw; }
  .minTingAction .minTingApp dl dd.amounDd { display: flex; justify-content: space-evenly; align-items: center;; }
  .minTingAction .minTingApp dl dd .btnAmount { width: 5.55555555556vw; height: 5.55555555556vw; font-size: 5vw; }
  .minTingAction .minTingApp dl dd input { width: 22.22222222222vw;  }

  .btnType01 { font-size: 3.33333333333vw; padding: 0 2.77777777778vw; line-height: 7.77777777778vw; border-radius: 4.44444444444vw; }

  .btnMinting { order: 3; display: block; margin: 4.16666666667vw auto 0; width: 88.33333333333vw; height: 9.72222222222vw; padding-top: 2.77777777778vw; font-size: 4.16666666667vw; background: url(images/bg/btnMintin_m.png) no-repeat center center; background-size: contain; filter: drop-shadow( 0px 0px 1.66666666667vw #fff ); }

  .conntectWallet { position: absolute; margin-top: 0; width: 100%; text-align: center; bottom: 66.66666666667vw;}

  button.connect { padding-left: 10vw; background: #fff url('images/img/metamask-fox.svg') no-repeat left 2.5vw center; background-size:  auto 60%;}
  button.connect:hover { color: #fff; background: #000 url('images/img/metamask-fox.svg') no-repeat left 2.5vw center; background-size:  auto 60%; border: 1px solid #fff; }

  .remain { order: 1; margin-top: 0; padding: 0 4.16666666667vw; line-height: 5.55555555556vw; font-size: 3.61111111111vw;  }
    

  .modal .modal-content { position: fixed; line-height: 3.33333333333vw; z-index: 99; width: 83.33333333333vw; padding: 4.16666666667vw; font-size: 3.33333333333vw; box-shadow: 0px 0px 39px rgba(0, 0, 0, 0.25); }
  .modal .modal-background { backdrop-filter: blur(0.703125vw); }
  .modal .modal-content__close { position: absolute; right: 4.16666666667vw; top: 4.16666666667vw;  }
  .modal .modal-content__close svg { width: 4.16666666667vw; height: 4.16666666667vw; }

}